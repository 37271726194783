@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

@media screen and (max-width: 1560px) {
  .smaller-image {
    width: 40%; /* Уменьшаем ширину изображения до 50% при ширине окна меньше 1024px */
    height: auto; /* Автоматически рассчитываем высоту, чтобы сохранить пропорции */
  }
  .services {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1920px) {
  .services {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1280px) {
  .services {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1024px) {
  .services {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .services {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 640px) {
  .services {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media screen and (max-width: 880px) {
  .image-none {
    display: none;
  }
}

@media screen and (max-width: 1080px) {
  .navbar-none {
    display: none;
  }
}
